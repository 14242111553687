import { Card } from 'components/card';
import { FileField } from 'components/fields';
import { Form } from 'components/form';
import { ManualMetadataStep } from 'components/sso/steps/saml/manual-metadata-step';
import { Article, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';
import { unreachable } from 'utils/unreachable';

export interface MetadataXmlStepProps extends ConnectionStepProps {
  stepTitle: string;
  idpEntityIdPlaceholder: string;
  idpSsoUrlPlaceholder: string;
}

export const MetadataXmlStep: React.FC<MetadataXmlStepProps> = ({
  children,
  idpEntityIdPlaceholder,
  idpSsoUrlPlaceholder,
  stepTitle,
  ...connectionStepProps
}) => {
  const {
    connectionUpdatedFields,
    errors,
    isLoading,
    onNextStep,
    setConnectionUpdatedFields,
    selectedConfiguration,
    setSelectedConfiguration,
  } = connectionStepProps;

  return (
    <Article>
      <Title className="mb-4">{stepTitle}</Title>

      {(() => {
        switch (selectedConfiguration) {
          case 'dynamic':
            return (
              <>
                {children}

                <Card>
                  <Form
                    disabled={!connectionUpdatedFields?.saml_idp_metadata_xml}
                    isLoading={isLoading}
                    isUpdate={!!errors?.saml_idp_metadata_xml}
                    onSubmit={onNextStep}
                    secondaryAction={() => setSelectedConfiguration('manual')}
                    secondaryText="Configure Manually"
                  >
                    <FileField
                      accept=".xml"
                      error={errors?.saml_idp_metadata_xml}
                      label="XML Metadata File"
                      name="saml_idp_metadata_xml"
                      onUpload={({ file }) => {
                        setConnectionUpdatedFields({
                          saml_idp_metadata_xml: file.content,
                        });
                      }}
                      value={connectionUpdatedFields?.saml_idp_metadata_xml}
                    />
                  </Form>
                </Card>
              </>
            );
          case 'manual':
            return (
              <ManualMetadataStep
                {...connectionStepProps}
                idpEntityIdPlaceholder={idpEntityIdPlaceholder}
                idpSsoUrlPlaceholder={idpSsoUrlPlaceholder}
                onConfigurationTypeChange={setSelectedConfiguration}
              />
            );
          default:
            return unreachable(selectedConfiguration);
        }
      })()}
    </Article>
  );
};
