import { InputGroup } from '@workos-inc/component-library';
import { Card } from 'components/card';
import React from 'react';

type CopyInputProps = {
  label: string;
  value?: string | null;
  type?: 'text' | 'password';
};

export const CopyInput: React.FC<Readonly<CopyInputProps>> = ({
  label,
  value,
  type,
}) => (
  <Card>
    <InputGroup
      isCopyable
      readOnly
      id="copy-input"
      isObfuscated={type === 'password'}
      label={label}
      name="copy-input"
      value={value ?? ''}
    />
  </Card>
);
