import { Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Confirm } from 'components/confirm';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { MetadataXmlStep } from 'components/sso/steps/saml/metadata-xml-step';
import { Article, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';

export const GoogleSamlAddApplication: React.FC<
  Readonly<ConnectionStepProps>
> = ({ onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 1: Add Custom SAML Application</Title>

    <Text>
      On your Google Admin dashboard, select "Apps" from the sidebar menu, and
      then select "Web and Mobile Apps" from the following list. On this page,
      select "Add App" and then "Add custom SAML app".
    </Text>

    <Img
      priority
      height={440}
      src="/admin-portal/sso/google-saml/v1/google-saml-1-ap.png"
      width={1075}
    />

    <Confirm label="I've added a custom SAML app." onClick={onNextStep} />
  </Article>
);

export const GoogleSamlEnterDetails: React.FC<
  Readonly<ConnectionStepProps>
> = ({ appName, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 2: Enter Details for your Custom App</Title>

    <Text>
      Enter an App name and icon (if applicable) for {appName}, then select
      "Continue".
    </Text>

    <Img
      priority
      height={732}
      src="/admin-portal/sso/google-saml/v1/google-saml-2-ap.png"
      width={1390}
    />

    <Confirm label="I've entered the App Details." onClick={onNextStep} />
  </Article>
);

export const GoogleSamlUploadMetadata: React.FC<
  Readonly<ConnectionStepProps>
> = (connectionStepProps) => (
  <MetadataXmlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://accounts.google.com/o/saml2?idpid=C00l3qzl9"
    idpSsoUrlPlaceholder="https://accounts.google.com/o/saml2/idp?idpid=C00l3qzl9"
    stepTitle="Step 3: Upload Metadata"
  >
    <Text>
      Select the "Download Metadata" button to download the metadata file, and
      upload it below. Click "Continue".
    </Text>

    <Img
      priority
      height={412}
      src="/admin-portal/sso/google-saml/v1/google-saml-download-metadata.png"
      width={1433}
    />
  </MetadataXmlStep>
);

export const GoogleSamlServiceProviderDetails: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 4: Enter Service Provider Details</Title>

    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <CopyInput label="Copy this Entity ID" value={connection?.saml_entity_id} />

    <Text>Submit the "ACS URL" and the "Entity ID". Click "Continue"</Text>

    <Img
      height={1712}
      src="/admin-portal/sso/google-saml/v1/google-saml-sp-details-ap.png"
      width={2683}
    />

    <Confirm
      label="I've configured the ACS URL and Entity ID in my custom SAML app."
      onClick={onNextStep}
    />
  </Article>
);

export const GoogleSamlAttributeMapping: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title>Step 5: Configure Attribute Mapping</Title>

    <Text>Provide the following Attribute Mappings and select "Finish":</Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Img
      height={728}
      src="/admin-portal/sso/google-saml/v1/google-saml-5-ap.png"
      width={1380}
    />

    <Confirm
      label="I've finished configuring the Attribute Mapping."
      onClick={onNextStep}
    />
  </Article>
);

export const GoogleSamlUserAccess: React.FC<Readonly<ConnectionStepProps>> = ({
  onNextStep,
}) => (
  <Article>
    <Title>Step 6: Configure User Access</Title>

    <Text>
      In the created SAML app's landing page, select the "User Access Section".
    </Text>

    <Img
      height={527}
      src="/admin-portal/sso/google-saml/v1/google-saml-6-ap.png"
      width={1443}
    />

    <Text>
      Turn this service ON for the correct organizational units in your Google
      Directory setup. Save any changes. Note that Google may take up to 24
      hours to propagate these changes, and the connection may be inactive until
      the changes have propagated.
    </Text>

    <Img
      height={361}
      src="/admin-portal/sso/google-saml/v1/google-saml-7-ap.png"
      width={1080}
    />

    <Confirm
      label="I've finished configuring the User Access."
      onClick={onNextStep}
    />
  </Article>
);
