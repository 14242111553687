import { Text } from '@workos-inc/component-library';
import { Card } from 'components/card';
import { FileField } from 'components/fields';
import { Form } from 'components/form';
import { CopyInput } from 'components/input';
import { Article, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';

export const OktaOINConfigureIntegration: React.FC<
  Readonly<ConnectionStepProps>
> = ({
  connection,
  onNextStep,
  setConnectionUpdatedFields,
  connectionUpdatedFields,
}) => (
  <Article>
    <Title className="mb-4">Step 1: Configure Okta Integration</Title>

    <Text>Use this ACS URL to configure your Okta settings.</Text>

    <CopyInput label="ACS URL" value={connection?.saml_acs_url} />

    <Form
      disabled={!connectionUpdatedFields.saml_idp_metadata_xml}
      onSubmit={onNextStep}
    >
      <Card>
        <Text>
          Then, upload your <strong>metadata.xml</strong> file to complete
          configuring your connection.
        </Text>

        <FileField
          accept=".xml"
          label="Okta Metadata File"
          name="metadata"
          onUpload={({ file }) => {
            // The `FileField` component renders a "clear" button, and if clicked,
            // calls `onUpload` with a `file` object, but the content is empty string.
            if (!file.content) {
              setConnectionUpdatedFields({
                saml_idp_metadata_xml: undefined,
              });
              return;
            }

            setConnectionUpdatedFields({
              saml_idp_metadata_xml: file.content,
            });
          }}
          value={connectionUpdatedFields.saml_idp_metadata_xml}
        />
      </Card>
    </Form>
  </Article>
);
