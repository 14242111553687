import { Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Confirm } from 'components/confirm';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { MetadataXmlStep } from 'components/sso/steps/saml/metadata-xml-step';
import { Article, Ol, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';

export const JumpCloudCreateApplication: React.FC<
  Readonly<ConnectionStepProps>
> = ({ appName, connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 1: Create SAML Application</Title>

    <Img
      priority
      height={1684}
      src="/admin-portal/sso/jump-cloud/v1/jump-cloud-1.png"
      width={3224}
    />

    <Text>
      From the JumpCloud Admin Console, select “SSO” on the navigation to the
      left. Then select the green “+” sign to create a new application.
    </Text>

    <Img
      priority
      height={1636}
      src="/admin-portal/sso/jump-cloud/v1/jump-cloud-2.png"
      width={2234}
    />

    <Text>In the subsequent pop-up window, select “Custom SAML App”.</Text>

    <Img
      height={1638}
      src="/admin-portal/sso/jump-cloud/v1/jump-cloud-3-edited.png"
      width={2208}
    />

    <Text>
      Under "Display Label", give it a descriptive name to easily identify it
      later such as {appName}-{connection?.name}.
    </Text>

    <Confirm
      label="I’ve finished naming the SAML Application"
      onClick={onNextStep}
    />
  </Article>
);

export const JumpCloudSubmitUrlEntityId: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 2: Submit ACS URL and Entity ID</Title>

    <Img
      priority
      height={1210}
      src="/admin-portal/sso/jump-cloud/v1/jump-cloud-4-edited.png"
      width={2224}
    />

    <Text>
      Copy the Entity ID into the "IdP Entity ID" and "SP Entity ID" fields.
    </Text>

    <CopyInput label="Copy this Entity ID" value={connection?.saml_entity_id} />

    <Text>Copy the ACS URL into the "ACS URL" field.</Text>

    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <Img
      height={712}
      src="/admin-portal/sso/jump-cloud/v1/jump-cloud-5.png"
      width={1490}
    />

    <Text>IMPORTANT: Be sure to check the “Sign Assertion” box.</Text>

    <Confirm
      label="I’ve configured the Assertion Consumer Service (ACS) URL and
          Entity ID."
      onClick={onNextStep}
    />
  </Article>
);

export const JumpCloudConfigureAttributeStatements: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 3: Configure Attribute Statements</Title>

    <Img
      priority
      height={802}
      src="/admin-portal/sso/jump-cloud/v1/jump-cloud-6.png"
      width={1506}
    />

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Img
      height={198}
      src="/admin-portal/sso/jump-cloud/v1/jump-cloud-11.png"
      width={531}
    />

    <Text>
      In the “User Attribute Mapping” section, click on the "add attribute"
      button to begin adding new user attributes. Add in the above Attribute
      Statements.
    </Text>

    <Img
      height={125}
      src="/admin-portal/sso/jump-cloud/v1/jump-cloud-7.png"
      width={397}
    />

    <Img
      height={568}
      src="/admin-portal/sso/jump-cloud/v1/jump-cloud-8.png"
      width={1302}
    />

    <Text>
      Once you’ve finished, select “activate” and then click on the “continue”
      button on the subsequent pop-up window.
    </Text>

    <Confirm
      label="I’ve finished configuring the Attribute Statements
          and clicked “activate” and “continue“."
      onClick={onNextStep}
    />
  </Article>
);

export const JumpCloudUploadMetadata: React.FC<
  Readonly<ConnectionStepProps>
> = (connectionStepProps) => (
  <MetadataXmlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://auth.workos.com/AbC123..."
    idpSsoUrlPlaceholder="https://sso.jumpcloud.com/..."
    stepTitle="Step 4: Upload Identity Provider Metadata"
  >
    <Text as="h2" size="large" weight="medium">
      URL Metadata Configuration
    </Text>

    <Ol className="my-4">
      <li>Click on the “SSO” tab of the SAML app you just created.</li>

      <li>Be sure to check "Declare Redirect Endpoint".</li>

      <Img
        height={1066}
        src="/admin-portal/sso/jump-cloud/v1/jump-cloud-12.png"
        width={2560}
      />

      <li>Click the "Export Metadata" button under "JumpCloud Metadata".</li>
      <li>A file will be downloaded. Upload it below.</li>
    </Ol>

    <Img
      height={1766}
      src="/admin-portal/sso/jump-cloud/v1/jump-cloud-13.png"
      width={2560}
    />
  </MetadataXmlStep>
);
